import allJson from "./ids/index"
const tableKeyName = {
	...allJson
}
/**
 * 根据模块获取对应翻译
 * @param {string} jsonName 文件名称
 * @param {string} key
 * @returns {string}
 */
export function isKeyName(jsonName,key) {
	let ids = tableKeyName[jsonName]||{}
	return ids[key] ? ids[key] : ''
}
