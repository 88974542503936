import request from "@/utils/request";

//获取码表
export function mbList(query) {
  return request({
    url: '/sys/webCode/allDataList',
    method: 'get',
    params: query
  })
}

//省市区码表
export function cityMbList(query) {
  return request({
    url: '/sys/mtssqdm/allList',
    method: 'get',
    params: query
  })
}

export function loginHr(data) {
  return request({
    url: '/loginHr',
    method: 'post',
    data
  })
}
//选择角色
export function chooseRole(params){
  return request({
    url:'/chooseRole',
    method:'post',
    params
  })
}
export function getCustom(params) {
  return request({
      url: '/base/query/get',
      method: 'get',
      params: params,
  })
}
export function saveCustom(data){
  return request({
    url:'/base/query/save',
    method:'post',
    data
  })
}

//领导角色查岗位
export function listPage(params) {
  return request({
      url: '/talent/recruitmentPositions/listPage',
      method: 'get',
      params: params,
  })
}

export function editJob(data) {
  return request({
    url: '/talent/recruitmentPositions/edit',
    method: 'post',
    data
  })
}
// 人才招聘岗位列表(我发起的)
export function initiatedListPage(params) {
  return request({
      url: '/talent/recruitmentPositions/initiatedListPage',
      method: 'get',
      params: params,
  })
}
// 人才招聘岗位列表(我审核过的)
export function approvedListPage(params) {
  return request({
      url: '/talent/recruitmentPositions/approvedListPage',
      method: 'get',
      params: params,
  })
}
// 人才招聘岗位列表(待我审核)
export function auditWaitListPage(params) {
  return request({
      url: '/talent/recruitmentPositions/auditWaitListPage',
      method: 'get',
      params: params,
  })
}
//删除人才招聘岗位
export function delJob(params) {
  return request({
    url: '/talent/recruitmentPositions/dels',
    method: 'post',
    params: params,
  })
}
//批量审核招聘岗位
export function batchAudit(data) {
  return request({
    url: '/talent/recruitmentPositions/batchAudit',
    method: 'post',
    data
  })
}
//更改人才招聘岗位发布状态
export function changeStatus(data) {
  return request({
    url: '/talent/recruitmentPositions/changeStatus',
    method: 'post',
    data
  })
}
export function downloadFile(params) {
  return request({
      url: '/sys/file/download',
      method: 'get',
      params: params,
      responseType:'blob',
  })
}
//首页人才引进政策列表
export function policyListPage(params) {
  return request({
      url: '/opening/talentIntroductionPolicy/listPage',
      method: 'get',
      params: params,
  })
}
//首页人才引进政策详情
export function policyInfo(params) {
  return request({
      url: '/opening/talentIntroductionPolicy/info',
      method: 'get',
      params: params,
  })
}
//首页咨询列表
export function consultationListPage(params) {
  return request({
      url: '/opening/unitPublicityConsultation/listPage',
      method: 'get',
      params: params,
  })
}
//首页咨询详情
export function consultationInfo(params) {
  return request({
      url: '/opening/unitPublicityConsultation/info',
      method: 'get',
      params: params,
  })
}
// 获取验证码
export function getVerificationCode(data) {
  return request({
    url: '/yw/jobSeekerUser/getVerificationCode',
    method: 'post',
    data
  })
}
// 求职者登录
export function jobSeekerUserLogin(data) {
  return request({
    url: '/yw/jobSeekerUser/login',
    method: 'post',
    data
  })
}
//求职者注册
export function register(data) {
  return request({
    url: '/yw/jobSeekerUser/save',
    method: 'post',
    data
  })
}
//人才招聘岗位列表
export function recruitmentPositionsListPage(params) {
  return request({
      url: '/opening/talentRecruitment/recruitmentPositionsListPage',
      method: 'get',
      params: params,
  })
}
//人才招聘岗位详情
export function recruitmentPositionsInfo(params) {
  return request({
    url: '/opening/talentRecruitment/recruitmentPositionsInfo',
    method: 'get',
    params: params,
  })
}
//投递人才招聘岗位
export function delivery(data) {
  return request({
    url: '/opening/talentRecruitment/delivery',
    method: 'post',
    data
  })
}

//查询求职者微简历信息
export function getJobSeekerResume(params) {
  return request({
    url: '/yw/jobSeekerUserMicroResume/info',
    method: 'get',
    params: params,
  })
}
//收藏人才招聘岗位
export function collectPositions(data) {
  return request({
    url: '/opening/talentRecruitment/collectPositions',
    method: 'post',
    data
  })
}
//研究生培养简章列表
export function recruitmentGuidelinesListPage(params) {
  return request({
    url: '/opening/graduateGuidelines/recruitmentGuidelinesListPage',
    method: 'get',
    params: params,
  })
}
//研究生培养简章详情
export function recruitmentGuidelinesInfo(params) {
  return request({
    url: '/opening/graduateGuidelines/recruitmentGuidelinesInfo',
    method: 'get',
    params: params,
  })
}
//研究生培养简章报名
export function graduateDelivery(data) {
  return request({
    url: '/opening/graduateGuidelines/delivery',
    method: 'post',
    data
  })
}
//研究生招聘简章投递列表
export function recruitmentGuidelinesDeliveryList(params) {
  return request({
    url: '/graduate/recruitmentGuidelinesDelivery/listPage',
    method: 'get',
    params: params,
  })
}
//求职者找回密码
export function retrievePassword(data) {
  return request({
    url: '/yw/jobSeekerUser/retrievePassword',
    method: 'post',
    data
  })
}
