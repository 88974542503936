import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getToken,removeToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
  baseURL: '/single', // url = base url + request url
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if(getToken()){
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if(response.request.responseType){
      return Promise.resolve(res)
    }else{
      if(res.code === 200){
        return Promise.resolve(res)
      }else if (res.code === 401) {
        ElMessage.closeAll()
        ElMessage.error({message:'您已退出登录,请重新登录', offset: 300})
        removeToken()
        localStorage.clear()
        setTimeout(()=>{
          location.reload()
        },1000)
        return Promise.reject(res)
      }else {
        ElMessage.closeAll()
        ElMessage.error({message:res.msg||'系统繁忙,请稍后重试', offset: 300})
        return Promise.reject(res)
      }
    }
  },
  error => {
    let data = error.response?error.response.data:null
    if(data){
      if (data.code === 401) {
        ElMessage.closeAll()
        ElMessage.error({message:'您已退出登录,请重新登录', offset: 300})
        removeToken()
        localStorage.clear()
        setTimeout(()=>{
          location.reload()
        },1000)
        return Promise.reject(error)
      }else{
        console.log('err' + error) // for debug
        ElMessage.closeAll()
        ElMessage.error({message:data.msg||'系统繁忙,请稍后重试', offset: 300})
        return Promise.reject(error)
      }
    }else{
      console.log('err' + error) // for debug
      ElMessage.closeAll()
      ElMessage.error({message:'系统繁忙,请稍后重试', offset: 300})
      return Promise.reject(error)
    }
  }
)

export default service
