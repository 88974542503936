import { createStore } from "vuex";
import getters from './getters'
import createPersistedState from "vuex-persistedstate"

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = createStore({
  modules,
  getters,
  plugins: [
    createPersistedState({
      storage:window.localStorage,
      key:'vuex',
      paths:['user','base']
    })
  ]
})

export default store
