<template>
  <div class="cardHeaderBox">
    <div class="left">
      <span>
        {{title}}
      </span>
    </div>
    <div class="right">
      <el-button type="primary" @click="close">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name:'ZyCardHeader',
  props:{
    title:{
      type:String,
      default:''
    }
  },
  methods:{
    close(){
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="less">
.cardHeaderBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
  .left{
    span{
      margin-left:5px;
      font-size: 18px;
      color: #333;
    }
  }
  .right{
    display: flex;
    align-items: center;
    i{
      cursor: pointer;
    }
  }
}
</style>
