import {mbList,cityMbList} from '@/api/index'
// import {getToken, removeToken, setToken} from '@/utils/auth'
// import {resetRouter} from '@/router'

const state = {
  positionsClassList:[ //岗位类型，分类
    {
      value:'1',
      label:"校园招聘"
    },
    {
      value:'2',
      label:"社会招聘"
    },
    {
      value:'3',
      label:"实习生招聘"
    },
    {
      value:'4',
      label:"博士后招聘"
    },
  ],
  //资讯类型
  consultationList:[
    {code:1,name:'校园资讯'},{code:2,name:'社会资讯'},{code:3,name:'博士后资讯'},{code:4,name:'实习生资讯'}
  ],
  //政策类型
  policyList:[
    {code:1,name:'校园招聘'},{code:2,name:'社会招聘'},{code:3,name:'博士后招聘'},{code:4,name:'实习生招聘'}
  ],
  auditStatusList:[ //审核状态
    {
      value: 0,
      label:"待审核"
    },
    {
      value: 1,
      label:"已通过"
    },
    {
      value: 2,
      label:"不通过"
    },
    {
      value: 3,
      label:"已撤销"
    },
  ],
  dmList:{},
  dmMap:{},
  city: [],
  cityTwo: [],
  cityTwoList: [],
  cityThree: [],
  cityTreeList: [],
}

const mutations = {
  SET_DM_LIST: (state, dmList) => {
    state.dmList = dmList
  },
  SET_DM_MAP: (state, dmMap) => {
    state.dmMap = dmMap
  },
  SET_CITY: (state, city) => {
    state.city = city
  },
  SET_CITY_TWO: (state, cityTwo) => {
    state.cityTwo = cityTwo
  },
  SET_CITY_THREE: (state, cityThree) => {
    state.cityThree = cityThree
  },
  SET_CITY_TREE_LIST: (state, cityTreeList) => {
    state.cityTreeList = cityTreeList
  },
  SET_CITY_TWO_LIST: (state, cityTwoList) => {
    state.cityTwoList = cityTwoList
  }
}

const actions = {
  getMb({commit}) {
    return new Promise((resolve, reject) =>  {
      mbList().then(data => {
        data.data['m_consultation_type']= state.consultationList
        data.data['m_policy_type']= state.policyList
        commit('SET_DM_LIST', data.data)
        let listMap = Object.assign({}, data.data)
        for (let i in listMap) {
          let obj = {}
          listMap[i].map(item => {
            obj[item.code] = item.name
          })
          listMap[i] = obj
        }
        cityMbList().then(data => {
          let list = data.data
          let object = {}
          for (let i = 0; i < list.length; i++) {
            object[list[i].code] = list[i].name
          }
          listMap['m_ssqdm'] = object
          commit('SET_DM_MAP', listMap)
        })
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getcity({commit}){
    return new Promise((resolve, reject) => {
      cityMbList().then(data => {
          let cityList = data.data
          let city = cityList.filter(item => {
            return item.level == 1
          })
          commit('SET_CITY', city)  //城市一级list

          let cityTwo = cityList.filter(item => {
            return item.level == 2
          })
          commit('SET_CITY_TWO', cityTwo) //城市二级list

          let cityThree = cityList.filter(item => {
            return item.level == 3
          })
          commit('SET_CITY_THREE', cityThree) //城市三级list


          commit('SET_CITY_TREE_LIST', getCityTreeList(city,cityTwo,cityThree))
          commit('SET_CITY_TWO_LIST', getCityTwoList(city,cityTwo))
          resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
// 城市三级
function getCityTreeList(city,cityTwo,cityThree) {
  let options = [];
  if (city && city.length > 0) {
    city.forEach((t) => {
      let item = {
        value: t.code,
        label: t.name,
        children: [],
      };
      if (cityTwo && cityTwo.length > 0) {
        cityTwo.forEach((p) => {
          if (t.id == p.parentId) {
            item.children.push({
              value: p.code,
              label: p.name,
              children: [],
            });
            if (cityThree && cityThree.length > 0) {
              cityThree.forEach((c) => {
                if (p.id == c.parentId) {
                  item.children[item.children.length - 1].children.push({
                    value: c.code,
                    label: c.name,
                  });

                }
              });
              // 删除children中没有数据的children，以便级联选择器的使用
              if (item.children[item.children.length - 1].children.length == 0) {
                delete item.children[item.children.length - 1].children
              }
            }
          }
        });
      }
      options.push(item);
    });
  }
  return options
}

// 城市两级
function getCityTwoList(city,cityTwo) {
  let options = [];
  if (city && city.length > 0) {
    city.forEach((t) => {
      let item = {
        value: t.code,
        label: t.name,
        children: [],
      };
      if (cityTwo && cityTwo.length > 0) {
        cityTwo.forEach((p) => {
          if (t.id == p.parentId) {
            item.children.push({
              value: p.code,
              label: p.name,
            });
          }
        });
      }
      options.push(item);
    });
  }
  return options
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
