import request from '@/utils/request'

//登录
export function jobSeekerUserLogin(data) {
  return request({
    url: '/yw/jobSeekerUser/login',
    method: 'post',
    data
  })
}
export function loginHr(data) {
  return request({
    url: '/loginHr',
    method: 'post',
    data
  })
}

export function logout(data) {
  return request({
    url: '/logout',
    method: 'post',
    data
  })
}

//获取路由
export function routerHr(data) {
  return request({
    url: 'base/personalCenter/routerHr',
    method: 'get',
    data
  })
}

//选择角色
export function chooseRole(params){
  return request({
    url:'/chooseRole',
    method:'post',
    params
  })
}

//获取图片验证码
export function getCode(data) {
  return request({
    url: '/base/slider/image',
    method: 'get',
    data
  })
}

//校验图片验证码
export function checkCode(data) {
  return request({
    url: '/base/slider/verification',
    method: 'post',
    data
  })
}

//获取个人资料
export function getInfo(query) {
  return request({
    url: '/base/personalCenter/info',
    method: 'get',
    params: query
  })
}

//获取码表
export function mbList(query) {
  return request({
    url: '/sys/webCode/allDataList',
    method: 'get',
    params: query
  })
}

//省市区码表
export function cityMbList(query) {
  return request({
    url: '/sys/mtssqdm/allList',
    method: 'get',
    params: query
  })
}

//编辑个人资料
export function updUserInfo(data) {
  return request({
    url: '/sys/personal/editInfo',
    method: 'post',
    data
  })
}

//修改密码
export function updPwd(data) {
  return request({
    url: '/base/personalCenter/changePasswd',
    method: 'post',
    data
  })
}

// 通用审核接口
export function allSh(data) {
  return request({
    url: '/task/completeTask',
    method: 'post',
    data
  })
}

// 批量审核审核接口
export function plSh(data) {
  return request({
    url: '/task/completeTasks',
    method: 'post',
    data
  })
}

//查询求职者信息
export function seekerInfo(params) {
  return request({
    url: '/yw/jobSeekerPersonalCenter/info',
    method: 'get',
    params: params,
  })
}
