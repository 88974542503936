import { createRouter,createWebHistory } from 'vue-router'
import {getToken,removeToken} from "@/utils/auth";
import store from "@/store/index";
import { ElMessageBox } from 'element-plus'

const routes = [
  {
    path: '/',
    name: 'homeView',
    component: () => import('../views/homeView.vue'),
    meta:{
    }
  },
  {
    path: '/hrLogin',
    name: 'HrLogin',
    component: () => import('../views/login/hrLogin.vue'),
    meta:{
      name:'单位登录',
    }
  },
  {
    path: '/jobSeekersLogin',
    name: 'JobSeekersLogin',
    component: () => import('../views/login/jobSeekersLogin.vue'),
    meta:{
      name:'求职者登录',
    }
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('../views/login/forgetPassword.vue'),
    meta:{
      name:'找回密码',
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/index.vue'),
    meta:{
      name:'求职者登录',
    }
  },
  {
    path: '/changeRole',
    component: () => import('@/views/changeRole/index'),
    hidden: true,
    meta:{
      name:'选择角色',
    }
  },
  {
    path: '/informationIndex',
    component: () => import('@/views/indexPage/informationIndex'),
    hidden: true,
    meta:{
      name:'资讯列表',
    }
  },
  {
    path: '/policyIndex',
    component: () => import('@/views/indexPage/policyIndex'),
    hidden: true,
    meta:{
      name:'政策列表',
    }
  },
  {
    path: '/policyDetails',
    component: () => import('@/views/indexPage/policyDetails'),
    hidden: true,
    meta:{
      name:'政策和资讯详情',
    }
  },
  {
    path: '/campusRecruitment',
    component: () => import('@/views/indexPage/campusRecruitment'),
    hidden: true,
    meta:{
      name:'校园招聘',
    }
  },
  {
    path: '/jobDetails',
    component: () => import('@/views/indexPage/jobDetails'),
    hidden: true,
    meta:{
      name:'岗位详情',
    }
  },
  {
    path: '/graduateStudents',
    component: () => import('@/views/indexPage/graduateStudents'),
    hidden: true,
    meta:{
      name:'研究生培养',
    }
  },
  {
    path: '/graduateDetails',
    component: () => import('@/views/indexPage/graduateDetails'),
    hidden: true,
    meta:{
      name:'研究生培养详情',
    }
  },
  {
    path: '/layoutMain',
    name: 'layoutMain',
    component: () => import('../views/layoutMain/index.vue'),
    meta:{
      name:'个人中心',
    },
    children: [
      {
        path: 'personalCenter',
        name:'PersonalCenter',
        meta:{
          isLogin: true,
          title:'个人中心',
        },
        component:() => import("../views/personalCenter/index"),
      },
      {
        path: 'message',
        name:'message',
        meta:{
          isLogin: true,
          title:'消息管理',
          isAuth:true
        },
        component:() => import("../views/message/index"),
      },
      {
        path: 'resumeDelivery',
        name:'resumeDelivery',
        meta:{
          isLogin: true,
          title:'投递简历管理',
          isAuth:true
        },
        component:() => import("../views/resumeDelivery/index"),
      },
      {
        path: 'interviewManagement',
        name:'interviewManagement',
        meta:{
          isLogin: true,
          title:'面试管理',
          isAuth:true
        },
        component:() => import("../views/interviewManagement/index"),
      },
      {
        path: 'assessmentManagement',
        name:'assessmentManagement',
        meta:{
          isLogin: true,
          title:'测评管理',
          isAuth:true
        },
        component:() => import("../views/assessmentManagement/index"),
      },
      {
        path: 'assessmentType',
        name:'assessmentType',
        meta:{
          isLogin: true,
          title:'测评类型管理',
          isAuth:true
        },
        component:() => import("../views/assessmentType/index"),
      },
      {
        path: 'evaluationCost',
        name:'evaluationCost',
        meta:{
          isLogin: true,
          title:'测评费用管理',
          isAuth:true
        },
        component:() => import("../views/evaluationCost/index"),
      },
      {
        path: 'talentPool',
        name:'talentPool',
        meta:{
          isLogin: true,
          title:'人才库管理',
          isAuth:true
        },
        component:() => import("../views/talentPool/index"),
      },
      {
        path: 'employedPersonnel',
        name:'employedPersonnel',
        meta:{
          isLogin: true,
          title:'已录用人员管理',
          isAuth:true
        },
        component:() => import("../views/employedPersonnel/index"),
      },
      {
        path: 'policyManagement',
        name:'policyManagement',
        meta:{
          isLogin: true,
          title:'政策管理',
          isAuth:true
        },
        component:() => import("../views/policyManagement/index"),
      },
      {
        path: 'jobManagement',
        name:'jobManagement',
        meta:{
          isLogin: true,
          title:'岗位管理',
          isAuth:true
        },
        component:() => import("../views/jobManagement/index"),
      },
      {
        path: 'informationManagement',
        name:'informationManagement',
        meta:{
          isLogin: true,
          title:'资讯管理',
          isAuth:true
        },
        component:() => import("../views/informationManagement/index"),
      },
      {
        path: 'loginLog',
        name:'loginLog',
        meta:{
          isLogin: true,
          title:'登录日志',
          isAuth:true
        },
        component:() => import("../views/loginLog/index"),
      },
      {
        path: 'registerUser',
        name:'registerUser',
        meta:{
          isLogin: true,
          title:'注册用户管理',
          isAuth:true
        },
        component:() => import("../views/registerUser/index"),
      },
      {
        path: 'changePassword',
        name:'changePassword',
        meta:{
          isLogin: true,
          title:'修改密码',
          isAuth:true
        },
        component:() => import("../views/changePassword/index"),
      },
      {
        path: 'graduateJobList',
        name:'graduateJobList',
        meta:{
          isLogin: true,
          title:'研究生培养简章',
          isAuth:true
        },
        component:() => import("../views/graduateJobList/index"),
      },
      //求职者路由
      {
        path: 'seekersPersonIndex',
        name:'seekersPersonIndex',
        meta:{
          isLogin: true,
          title:'求职者首页',
        },
        component:() => import("../views/seekersPersonIndex/index"),
      },
      {
        path: 'minResume',
        name:'minResume',
        meta:{
          isLogin: true,
          title:'我的微简历',
        },
        component:() => import("../views/minResume/index"),
      },
      {
        path: 'myDeliveryemployment',
        name:'myDeliveryemployment',
        meta:{
          isLogin: true,
          title:'我的投递与录用',
        },
        component:() => import("../views/myDeliveryemployment/index"),
      },
      {
        path: 'myCollection',
        name:'myCollection',
        meta:{
          isLogin: true,
          title:'我的收藏',
        },
        component:() => import("../views/myCollection/index"),
      },
      {
        path: 'myAssessment',
        name:'myAssessment',
        meta:{
          isLogin: true,
          title:'我的测评',
        },
        component:() => import("../views/myAssessment/index"),
      },
    ],
  }
]
// const whiteList = ["/hrLogin"]
const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV === "test" ? `https://cs.91boshuo.com/` : process.env.NODE_ENV === "production" ? `/` : '/'),
  routes
})


router.beforeEach(async (to, from, next) => {
  const hasToken = getToken();
  if (hasToken) {
    if(!store.state.user.isGetRouter && store.state.user.isChangedRole){
      if(store.state.user.userInfo.sysUser.userFrom == 1){
        store.dispatch('user/routerHr').then(()=>{
          const  canToRouterList = store.state.user.userRouterList.map(item=>{
            return item.menuPath
          })
          if(to.meta.isAuth){
            if(canToRouterList.indexOf(to.path) !== -1){
              next();
            }else{
              ElMessageBox.close()
              ElMessageBox.alert('您没有被授权，请联系管理员授权后访问', '提示', {
                confirmButtonText: '确定',
              }).then(() => {
                next({path:'/'}); 
              })
            }
          }else{
            next();
          }
        })
      }else{
        next();
      }
      
    }else{
      const  canToRouterList = store.state.user.userRouterList.map(item=>{
        return item.menuPath
      })
      if(to.meta.isAuth){
        if(canToRouterList.indexOf(to.path) !== -1){
          next();
        }else{
          ElMessageBox.close()
          ElMessageBox.alert('您没有被授权，请联系管理员授权后访问', '提示', {
            confirmButtonText: '确定',
          }).then(() => {
            next({path:'/hrLogin'}); 
          })
        }
      }else{
        next();
      }
    }
  } else {
    removeToken()
    store.dispatch('user/setTokenActive','')
    store.dispatch('user/chbangedRole',false)
    store.dispatch('user/chIsLogin',false)
    if (!to.meta.isLogin) {
      next();
    } else {
      if(to.path == '/'){
        next()
      }else{
        next('/hrLogin')
      }
    }
  }
});

export default router
