import {getInfo, jobSeekerUserLogin, logout , loginHr, routerHr,seekerInfo} from '@/api/user'
import {getToken, removeToken, setToken} from '@/utils/auth'
import routerList from '@/router/jobSeekerRouter'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  curRoles: {},
  userInfo: {},
  userRouterList:[],
  isGetRouter:false,
  isChangedRole:false,
  isLogin:false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_ID: (state, userid) => {
    state.userInfo.userid = userid
  },
  SET_USER: (state, userInfo) => {
    state.userInfo = userInfo
  },
  GET_USER_ROUTER_LIST:(state, data) =>{
    state.userRouterList = data
    state.isGetRouter = true
  },
  CHANGE_ROLE:(state,data) =>{
    state.isChangedRole = data
  },
  IS_LOGIN:(state,data) =>{
    state.isLogin = data
  },
  CUR_ROLES:(state,data) =>{
    state.curRoles = data
  }
}

const actions = {
  chbangedRole({commit},data){
    commit('CHANGE_ROLE',data)
  },
  setTokenActive({commit},data){
    commit('SET_TOKEN',data)
  },
  chIsLogin({commit},data){
    commit('IS_LOGIN', data)
  },
  // user login
  loginHr({commit}, userInfo) {
    return new Promise((resolve, reject) => {
      loginHr(userInfo).then(response => {
        const data = response.data
        commit('SET_TOKEN', data.token)
        commit('IS_LOGIN', true)
        // commit('SET_USER_ID',data.userid)
        setToken(data.token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  jobSeekerLogin({commit}, userInfo) {
    return new Promise((resolve, reject) => {
      jobSeekerUserLogin(userInfo).then(response => {
        const data = response.data
        commit('SET_TOKEN', data.token)
        commit('IS_LOGIN', true)
        // commit('SET_USER_ID',data.userid)
        setToken(data.token)
        setTimeout(()=>{
          let obj = {type:'login',code:2}
          new BroadcastChannel('jy').postMessage(obj)
        },1000)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({commit, state}) {
    return new Promise((resolve, reject) => {
      getInfo().then(data => {
        if (data.code != 200) {
          reject('Verification failed, please Login again.')
        }
        console.log(state)
        commit('SET_USER', data.data)
        // sessionStorage.setItem('permissions', JSON.stringify(data.data.menuPermissions || '[]'))
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getSeekerInfo({commit},phone) {
    return new Promise((resolve, reject) => {
      seekerInfo({phone:phone}).then(data => {
        if (data.code != 200) {
          reject('Verification failed, please Login again.')
        }
        console.log(state)
        commit('SET_USER', data.data)
        // sessionStorage.setItem('permissions', JSON.stringify(data.data.menuPermissions || '[]'))
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  getCurrentRoles({commit},data){
    commit('CUR_ROLES', data)
  },
  // user logout
  logout({commit, state}) {
    return new Promise((resolve, reject) => {
      logout().then(response => {
        if (response.code == 200) {
          commit('SET_TOKEN', '')
          commit('IS_LOGIN', false)
          commit('CHANGE_ROLE',false)
          commit('CUR_ROLES', {})
          commit('GET_USER_ROUTER_LIST', [])
          removeToken()
          // resetRouter()
          // sessionStorage.removeItem('permissions')
          localStorage.removeItem('phone')
          sessionStorage.clear()
          localStorage.clear()
          // dispatch('tagsView/delAllViews', null, {root: true})
          // dispatch('permission/removeRoutes', null, {root: true})
          console.log(state)
          resolve()
          let obj = {type:'logout',code:1}
          new BroadcastChannel('jy').postMessage(obj)
        } else {
          resolve(response)
        }
      }).catch(error => {
        sessionStorage.clear()
        localStorage.clear()
        reject(error)
      })
    }) 
  },

  // remove token
  resetToken({commit}) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  routerHr({commit}){
    return new Promise((resolve, reject) => {
      routerHr().then(res=>{
        let arr = []
        res.data.forEach(element => {
          element.childs.forEach(x=>{
            if( x.menuName != 'h5面试记录详情' && x.menuName != '统计概览'){
              arr.push(x)
            }
          })
        });
        commit('GET_USER_ROUTER_LIST', arr)
        resolve(arr)
      }).catch(error => {
        reject(error)
      })
    })
  },
  routeJobSeeker({commit}){
    return new Promise((resolve) => {
      commit('GET_USER_ROUTER_LIST', routerList.routerList)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
