import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zyCardHeader from "@/components/zyCardHeader"
import 'element-plus/dist/index.css'
import * as ElIconList from '@element-plus/icons-vue'
import mitt from "mitt"
import {isAuth} from '@/utils'

import 'dayjs/locale/zh-cn';
import locale from "element-plus/lib/locale/lang/zh-cn";

import { isKeyName } from '@/json/keyName.js'

import "./icons"

const app = createApp(App)

app.component('zyCardHeader',zyCardHeader).use(store).use(router).use(ElementPlus, { locale }).mount('#app')
for (const name in ElIconList) {
  app.component(name, ElIconList[name])
}
app.config.globalProperties.isKeyName = isKeyName
app.config.globalProperties.mittBus = new mitt()
app.config.globalProperties.isAuth = isAuth