const routerList = [
  {
    menuIcon: "chart",
    menuName: "个人中心",
    menuPath: "/layoutMain/seekersPersonIndex",
    sort:1
  },
  {
    menuIcon: "nested",
    menuName: "我的微简历",
    menuPath: "/layoutMain/minResume",
    sort:1
  },
  {
    menuIcon: "component",
    menuName: "我的投递与录用",
    menuPath: "/layoutMain/myDeliveryemployment",
    sort:2
  },
  {
    menuIcon: "size",
    menuName: "我的收藏",
    menuPath: "/layoutMain/myCollection",
    sort:3
  },
  {
    menuIcon: "example",
    menuName: "我的测评",
    menuPath: "/layoutMain/myAssessment",
    sort:4
  },
  {
    menuIcon: "tab",
    menuName: "登录日志",
    menuPath: "/layoutMain/loginLog",
    sort:5
  },
  {
    menuIcon: "edit",
    menuName: "修改密码",
    menuPath: "/layoutMain/changePassword",
    sort:6
  },
]

export default {
  routerList
}