<template>
  <div id="app">
    <router-view :key="route.fullPath"/>
    <el-backtop :right="100" :bottom="100" />
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute,useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  store.dispatch('base/getMb')
  store.dispatch('base/getcity')
  onMounted(() => {
    new BroadcastChannel('jy').onmessage = (e)=>{
      let obj = e.data
      let url = window.location.pathname
      if(obj.type == 'login'){
        //code是1是hr登录，2是求职者登录
        if(obj.code == 1 && (url.indexOf('/hrLogin') > -1 || url.indexOf("/jobSeekersLogin") > -1)){
          store.dispatch('user/getInfo').then(x=>{
            localStorage.setItem('permissions', JSON.stringify(x.data.menuPermissions || '[]'))
          })
          store.dispatch('user/chbangedRole',true)
          store.dispatch('user/chIsLogin', true)
          store.dispatch('user/routerHr').then((resArr)=>{
            let currentObj = localStorage.getItem('currentObj')
            store.dispatch('user/getCurrentRoles',JSON.parse(currentObj))
            if(resArr.length > 0){
              goto(resArr[0].menuPath)
            }else{
              ElMessageBox.close()
              ElMessageBox.alert('您没有被授权，请联系管理员授权后访问', '提示', {
                confirmButtonText: '确定',
              }).then(() => {
                goto("/");
              })
            }
          })
        }else if(obj.code == 2 && (url.indexOf('/hrLogin') > -1 || url.indexOf("/jobSeekersLogin") > -1)){
          let phone = localStorage.getItem("phone")
          store.dispatch('user/getSeekerInfo',phone).then(response=>{
            store.dispatch('user/chbangedRole',true)
            store.dispatch('user/routeJobSeeker')
            let object = response.data
            object['roleName'] = response.data.fullName
            object['userFrom'] = 2
            store.dispatch('user/getCurrentRoles', object)
            store.dispatch('user/chIsLogin', true)
            goto('/layoutMain/seekersPersonIndex')
          })
        }
      }else if(obj.type == 'logout'){
        location.reload()
      }
    }
  })
  const goto=(val)=>{
    router.push({
      path:val,
    })
  }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body{
  margin: 0;
  padding: 0;
  color: #333333;
}
/* 整个滚动条*/
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: #DDDEE0;
    border-radius: 32px;
}
::-webkit-scrollbar-thumb:hover{
    background-color: #C7C9CC;
}
/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 32px;
}
.searchForm{
  font-size: 12px;
  margin-bottom: 20px;
}
.ruleForm /deep/ .el-form-item{
  width: 100%;
}
.tableData{
  margin-bottom: 10px;
}
.pagination{
  justify-content: end;
}
.el-dialog__header{
  margin-right: 0px !important;
  background-color: #f2f2f2;
}
.el-table__body-wrapper{
  max-height: 400px;
  overflow-y: auto !important;
}
.overTextStyle{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.overTextStyle p{ 
  margin: 0;
}
.el-popper{
  max-width: 600px;
}
.orange{
  color: #FF951C;
}
.green{
  color: #22C542;
}
.red{
  color: #FF4E4E;
}
.defaultColor{
  color: #0072E5;
}
.defaultColor:hover{
  opacity: 0.8;
}
.flex{
  display: flex;
}
.flex-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fontSize14{
  font-size: 14px;
}
.fontSize12{
  font-size: 14px;
}
.cursorPointer{
  cursor: pointer
}
/deep/ .el-tooltip{
  max-height: 500px;
  overflow-y: auto;
}
</style>
